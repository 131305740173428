import { createTheme } from '@mui/material/styles'

import { customThemeColors } from './colors'
import { typography, typographyCssBaselineStyleOverrides } from './typography'

const sectionTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1440,
    },
  },
  palette: {
    mode: 'light',
    primary: {
      main: customThemeColors.primaryColors.regGreen,
    },
    secondary: {
      main: customThemeColors.primaryColors.actionGrey,
    },
    success: {
      main: customThemeColors.primaryColors.lightGreen,
    },
    error: {
      main: customThemeColors.trafficColors.alertRed,
    },
    background: {
      default: customThemeColors.primaryColors.offWhite,
    },
    ...customThemeColors,
    // legacy, these should be factored out or better named
    box: {
      // TODO: investigate how to replace this color (its not defined in the design system)
      main: '#202020',
    },
    inputBox: {
      main: customThemeColors.primaryColors.labelsGrey,
    },
    inputBorder: {
      // TODO: investigate how to replace this color (its not defined in the design system)
      main: '#000000',
    },
  },
  typography,
  components: {
    MuiTab: {
      defaultProps: {
        iconPosition: 'start',
      },
      styleOverrides: {
        root: {
          textTransform: 'none',
          color: 'rgba(255, 255, 255, 0.8)',
          flexDirection: 'row',
          justifyContent: 'space-evenly',
          alignContent: 'center',
          transition: '250ms',
        },
        labelIcon: {
          height: '100%',
          marginBottom: 0,
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        // TODO: Investigate what these styles were trying to do; remove if possible
        // background: '#191d28',
        // boxShadow: '0 4px 12px rgba(100, 99, 99, 0.07)',
        // display: 'flex',
        // flexWrap: 'nowrap',
        // flexDirection: 'row',
        // justifyContent: 'space-between',
        // alignItems: 'center',
        // alignContent: 'flex-start',
      },
    },
    MuiButton: {
      styleOverrides: {
        outlined: {
          backgroundColor: customThemeColors.primaryColors.actionGrey,
          border: '1px solid rgba(0, 0, 0, 0.1)',
          textTransform: 'none',
          color: 'white',
          marginLeft: 1,
          '&:hover': {
            backgroundColor: customThemeColors.primaryColors.actionGreyHover,
            border: '1px solid rgba(0, 0, 0, 0.1)',
          },
        },
        text: {
          textTransform: 'none',
        },
      },
    },
    MuiGrid: {
      styleOverrides: {
        root: {
          marginLeft: '0px',
        },
        item: {
          paddingLeft: '0px !important',
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          fontWeight: 400,
          fontSize: '16px',
          lineHeight: '15px',
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: 'hover',
      },
      styleOverrides: {
        root: {
          color: customThemeColors.primaryColors.regGreen,
          ':hover': {
            color: customThemeColors.activityColors.selectedNeonGreen,
          },
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: typographyCssBaselineStyleOverrides,
    },
    // TODO: Investigate what these styles were trying to do; remove if possible
    // FormLabel: {
    //   styleOverrides: {
    //     color: 'rgba(255, 255, 255, 0.85)',
    //   },
    // },
    // nav: {
    //   background: '#191d28',
    // },
  },
})

export default sectionTheme
