import { TypographyOptions } from '@mui/material/styles/createTypography'

export const typography: TypographyOptions = {
  fontFamily: ['GT America', 'Open Sans'].join(','),
}

export const typographyCssBaselineStyleOverrides = `
  @font-face {
    font-family: 'GT America';
    src: url('/console-assets/fonts/GTAmerica-Regular.woff') format('woff2');
    font-weight: normal;
  }

  @font-face {
    font-family: 'GT America';
    src: url('/console-assets/fonts/GTAmerica-Medium.woff') format('woff2');
    font-weight: 500;
  }

  @font-face {
    font-family: 'GT America';
    src: url('/console-assets/fonts/GTAmerica-Bold.woff') format('woff2');
    font-weight: 700;
  }

  @font-face {
    font-family: 'GT Sectra';
    src: url('/console-assets/fonts/GTSectra-Bold.woff') format('woff2');
    font-weight: 700;
  }

  @font-face {
    font-family: 'GT America';
    src: url('/console-assets/fonts/GTAmerica-Regular.woff2') format('woff2');
    font-weight: normal;
  }

  @font-face {
    font-family: 'GT America';
    src: url('/console-assets/fonts/GTAmerica-Medium.woff2') format('woff2');
    font-weight: 500;
  }

  @font-face {
    font-family: 'GT America';
    src: url('/console-assets/fonts/GTAmerica-Bold.woff2') format('woff2');
    font-weight: 700;
  }

  @font-face {
    font-family: 'GT Sectra';
    src: url('/console-assets/fonts/GTSectra-Bold.woff2') format('woff2');
    font-weight: 700;
  }
`
