import { forwardRef } from 'react'

import Close from '@mui/icons-material/Close'
import { Alert, Button, Typography } from '@mui/material'
import { Box } from '@mui/system'

import { BannerNotification as BannerNotificationType } from '../contexts/BannerContext'
import MaterialNextLink from 'common/components/router/MaterialNextLink'

type BannerNotificationProps = {
  handleClose: () => void
  notification: BannerNotificationType
}

const BannerNotificationActionButtonTextStyles = {
  textDecoration: 'underline',
  color: 'white',
  '&:hover': { color: 'primaryColors.offWhite' },
}

const BannerNotification = forwardRef<HTMLDivElement, BannerNotificationProps>(({ handleClose, notification }, ref) => (
  <Alert
    data-cy="notification-banner"
    id="notification-banner"
    ref={ref}
    icon={false}
    variant="filled"
    severity={notification.variant}
    sx={{ color: 'white' }}
  >
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Typography variant="subtitle1" sx={{ marginRight: '24px' }}>
        {notification.message}
      </Typography>
      <Box
        sx={{
          '&.MuiBox-root': {
            alignItems: 'center',
            display: 'flex',
            marginLeft: 'auto',
          },
        }}
      >
        <Button
          onClick={handleClose}
          disableRipple
          sx={{
            '&.MuiButton-root': {
              display: 'block',
              minWidth: '0px',
              padding: 0,
            },
            '&.MuiButton-root:hover': {
              background: 'none',
            },
          }}
        >
          <Typography variant="button" sx={BannerNotificationActionButtonTextStyles}>
            <Close />
          </Typography>
        </Button>
        {notification.links?.map((link, i) => (
          <MaterialNextLink
            href={link.href}
            key={`${link.href}-${link.name}-${i}`}
            sx={{ marginLeft: '32px' }}
            target="_blank"
            rel="noopener"
          >
            <Typography
              color="primaryColors.onDarkActionGrey"
              variant="button"
              sx={BannerNotificationActionButtonTextStyles}
            >
              {link.name}
            </Typography>
          </MaterialNextLink>
        ))}
      </Box>
    </Box>
  </Alert>
))
BannerNotification.displayName = 'BannerNotification'

export default BannerNotification
