import Head from 'next/head'
import Script from 'next/script'

export default function AppMeta() {
  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />

        <link rel="shortcut icon" href="/favicon.ico" />

        {/* Inform the browser that this page only supports a dark color scheme. */}
        <meta name="color-scheme" content="dark" />

        <meta name="theme-color" content="#000000" />

        <link rel="apple-touch-icon" href="/logo152.png" />
        <link rel="manifest" href="/manifest.json" />

        <title>Section Console</title>
        <meta
          name="description"
          content="Section&#39;s Edge Compute Platform gives developers code-level control over edge configuration and deployment for application performance, security, and scalability."
        />
      </Head>
      <Script id="google-tag-manager" strategy="afterInteractive">
        {`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://sgtm.section.io/sgtm.js?id='+i+dl+ '${process.env.NEXT_PUBLIC_GTM_EXTRA_PARAMS}';f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GTM_ID}');
        `}
      </Script>
      <Script src="https://js.chargify.com/latest/chargify.js" async={false} />
    </>
  )
}
