import { useEffect, useMemo, useReducer, useState } from 'react'

import { Fade, Snackbar } from '@mui/material'

import BannerNotification from './components/BannerNotification'
import { BannerContext, BannerNotification as BannerNotificationType } from './contexts/BannerContext'
import { BannerDispatchContext, REMOVE, bannerReducer, initialState } from './contexts/BannerContext'

interface BannerProviderProps {
  /**
   * Forwarded to Snackbar's autoHideDuration prop
   */
  autoHideDuration?: number
}

export const BannerProvider = ({ autoHideDuration, children }: React.PropsWithChildren<BannerProviderProps>) => {
  const [state, dispatch] = useReducer(bannerReducer, initialState)
  const [isOpen, setIsOpen] = useState(false)
  const [currentNotification, setCurrentNotification] = useState<BannerNotificationType>()

  useEffect(() => {
    if (state.length && !currentNotification) {
      const notification = state[0]
      setCurrentNotification(notification)
      dispatch({ type: REMOVE, id: notification.id })
      setIsOpen(true)
    } else if (state.length && currentNotification && isOpen) {
      setIsOpen(false)
    }
  }, [currentNotification, isOpen, state])

  function handleClose(_event: React.SyntheticEvent | Event | undefined, reason: string) {
    if (reason === 'clickaway') return
    setIsOpen(false)
  }

  function handleExited() {
    setCurrentNotification(undefined)
  }

  return (
    <BannerContext.Provider value={state}>
      <BannerDispatchContext.Provider value={useMemo(() => dispatch, [dispatch])}>
        {children}
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          autoHideDuration={autoHideDuration || 1500}
          onClose={handleClose}
          open={isOpen}
          TransitionComponent={Fade}
          TransitionProps={{ onExited: handleExited, timeout: { enter: 250, exit: 800 } }}
        >
          {/* wrapper div required for proper entrance transition */}
          <div>
            {currentNotification && (
              <BannerNotification
                handleClose={() => {
                  handleClose(undefined, 'dismiss')
                }}
                notification={currentNotification}
              />
            )}
          </div>
        </Snackbar>
      </BannerDispatchContext.Provider>
    </BannerContext.Provider>
  )
}
