import { createContext } from 'react'

type SectionUserContext = {
  selectedAccount: string
  setSelectedAccount: (c: string) => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sectionUser: any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setSectionUser: (c: any) => void
  selectedTheme: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setSelectedTheme: (c: any) => void
}

export const SectionUserContext = createContext<SectionUserContext>({
  selectedAccount: '',
  sectionUser: {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setSelectedAccount: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setSectionUser: () => {},
  selectedTheme: '1',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setSelectedTheme: () => {},
})
