import { gql } from 'graphql-request'

export const GET_CURRENT_USER_INFO = gql`
  query GetCurrentUserInfo {
    getCurrentUser {
      first_name
      last_name
      email
      id
      company_name
      phone_number
      requires2fa
      auth0userid
      accounts {
        id
        name
        type
        tenantID
      }
      signedUp
      createdAt
    }
  }
`
export const GET_USER_MFA_METHODS = gql`
  query UserAuthMethods($auth0UserID: String!) {
    userAuthMethods(auth0UserID: $auth0UserID) {
      id
      authType
      confirmed
      createdAt
      lastAuthAt
    }
  }
`

export const UPDATE_USER_INFO = gql`
  mutation UpdateUser(
    $userID: ID!
    $email: String!
    $firstName: String!
    $lastName: String!
    $companyName: String!
    $phoneNumber: String!
    $requires2fa: Boolean!
  ) {
    updateUser(
      userID: $userID
      email: $email
      firstName: $firstName
      lastName: $lastName
      companyName: $companyName
      phoneNumber: $phoneNumber
      requires2fa: $requires2fa
    ) {
      success
    }
  }
`

export const GET_ACCOUNT_USERS = gql`
  query GetCurrentAccount($accountId: ID!) {
    account(id: $accountId) {
      id
      users {
        id
        first_name
        last_name
        role
        email
      }
      invitedUsers {
        email
      }
    }
  }
`

export const INVITE_USER = gql`
  mutation InviteUser($accountId: String!, $email: String!) {
    inviteUser(input: { accountId: $accountId, email: $email }) {
      success
    }
  }
`
export const REMOVE_USER = gql`
  mutation RemoveUserFromAccount($accountId: String!, $email: String!) {
    removeUserFromAccount(input: { accountId: $accountId, email: $email }) {
      isRemoved
    }
  }
`
export const GET_USER_API_TOKENS = gql`
  query UserAPITokens {
    userAPITokens {
      id
      note
      capabilityString
      isRevoked
      createdAt
      updatedAt
    }
  }
`

export const CREATE_USER_API_TOKEN = gql`
  mutation createToken($note: String, $temp: Boolean) {
    createUserAPIToken(note: $note, temp: $temp)
  }
`

export const DELETE_USER_API_TOKEN = gql`
  mutation deleteUserAPIToken($id: String!) {
    deleteUserAPIToken(id: $id)
  }
`
export const DELETE_USER_API_TOKENS = gql`
  mutation deleteUserAPITokens($id: [String!]!) {
    deleteUserAPITokens(id: $id)
  }
`

export const GET_ACCOUNT_API_TOKENS = gql`
  query AccountAPITokens($accountID: ID!) {
    listAccountAPITokens(accountID: $accountID) {
      id
      note
      capabilityString
      isRevoked
      createdAt
      updatedAt
    }
  }
`

export const CREATE_ACCOUNT_API_TOKEN = gql`
  mutation createAccountToken($note: String, $accountID: ID!, $accessLevel: String!) {
    createAccountAPIToken(note: $note, accountID: $accountID, accessLevel: $accessLevel)
  }
`

export const DELETE_USER_AUTH_METHOD = gql`
  mutation deleteUserAuthMethod($authMethodID: String!) {
    deleteUserAuthMethod(authMethodID: $authMethodID)
  }
`

export const RESEND_VERIFICATION_EMAIL = gql`
  mutation ResendVerificationEmail($userID: String!) {
    resendVerificationEmail(userID: $userID)
  }
`
export const GET_GITHUB_REPOSITORIES = gql`
  query GithubRepositories {
    githubRepositories {
      name
    }
  }
`
export const CREATE_GITHUB_ACCESS_TOKEN = gql`
  mutation CreateGithubAccessToken($githubCode: String!) {
    createGithubAccessToken(githubCode: $githubCode)
  }
`
