import { useState } from 'react'

import { useAuth0 } from '@auth0/auth0-react'

import { CREATE_ORG } from '../queries'
import { consoleFetcher } from 'common/fetchers/graphql'

interface CreateOrgData {
  loading: boolean
  err: null | string
  data: string | null
}

interface OrgCreateData {
  name: string
}

export default function useCreateOrg() {
  const { getAccessTokenSilently } = useAuth0()

  const [requestState, setRequestState] = useState<CreateOrgData>({
    loading: false,
    err: null,
    data: null,
  })

  async function createOrg(orgCreateData: OrgCreateData, tenantId: string, orgType: string) {
    try {
      setRequestState({ ...requestState, loading: true })
      const response = await consoleFetcher(
        CREATE_ORG,
        {
          tenantID: tenantId,
          name: orgCreateData.name,
          type: orgType,
        },
        { tokenFn: getAccessTokenSilently }
      )

      if (!response?.createAccount) throw new Error(response?.errors[0]?.message || 'API returned an invalid response')

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      dataLayer?.push({
        event: 'org_created',
      })

      // TODO populate this with the correct data
      setRequestState({ ...requestState, data: 'test', loading: false })
      return {
        success: true,
        orgId: response?.createAccount as string,
        err: null,
      }
    } catch (error) {
      const errorMessage = error as Error

      setRequestState({ ...requestState, err: errorMessage.message, loading: false })
      return {
        success: false,
        orgId: null,
        err: errorMessage.message,
      }
    }
  }

  const resetCreateOrg = () => {
    setRequestState({ err: null, data: null, loading: false })
  }

  return {
    createOrg,
    resetCreateOrg,
    err: requestState.err,
    loading: requestState.loading,
    data: requestState.data,
  }
}
