import { Auth0ContextInterface, useAuth0 } from '@auth0/auth0-react'
import useSWR from 'swr'

const fetcher = async (url: string, tokenFn: Auth0ContextInterface['getAccessTokenSilently']) => {
  let authorizationHeader = ''
  try {
    authorizationHeader = `Bearer ${await tokenFn()}`
  } catch (e) {
    // TODO: Add error logging
  }
  const res = await fetch(url, {
    headers: {
      Authorization: authorizationHeader,
    },
  })

  // If the status code is not in the range 200-299
  if (!res.ok) {
    const error = new Error('An error occurred while fetching the data.')
    throw error
  }

  return res.json()
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const defaultReturn: any[] = []

export default function useFetchToggles(unleashURL: string) {
  const { getAccessTokenSilently } = useAuth0()
  const { data, error } = useSWR([unleashURL, getAccessTokenSilently], fetcher, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  })
  if (error) return defaultReturn
  if (data) return data.features

  return []
}
