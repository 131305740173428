import { useContext, useState } from 'react'

import Link from 'next/link'

import { AddCircle } from '@mui/icons-material'
import CorporateFareIcon from '@mui/icons-material/CorporateFare'
import { Box, FormControl, MenuItem, Select, Tooltip } from '@mui/material'

import AddOrgModal from './AddOrgModal'
import useFeatureToggle from 'common/components/featuretoggle/useFeatureToggle'
import { SectionUserContext } from 'common/contexts/SectionUserContext'

interface Account {
  id: string
  name: string
  type: string
}

// TODO - use the correct type for accounts when we transition to SWR for account fetching
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function AccountSwitcher({ accountId, collapsed }: { accountId: string; collapsed: boolean }) {
  const [showOrgModal, setShowOrgModal] = useState(false)

  const {
    sectionUser: { accounts },
  } = useContext(SectionUserContext)

  const shouldShowAddAccount = useFeatureToggle('console.shouldShowAddAccount', true, {
    accountID: accountId,
  })

  const showAddAccount = shouldShowAddAccount && accounts?.some((account: Account) => account.type === 'enterprise')

  if (collapsed) {
    return (
      <Tooltip placement="right" arrow title={accounts?.find((account: Account) => account.id === accountId)?.name}>
        <Box sx={{ my: 1, padding: '16px 32px', width: '100%' }}>
          <CorporateFareIcon fontSize="small" sx={{ marginRight: '12px' }} />
        </Box>
      </Tooltip>
    )
  }

  return (
    <>
      <FormControl sx={{ m: 1, minWidth: 120, padding: '8px 16px' }}>
        <Select
          value={accountId}
          onChange={() => null}
          displayEmpty
          disableUnderline
          variant="standard"
          inputProps={{ 'aria-label': 'Account selector' }}
          sx={{
            color: 'primaryColors.white',
            '& .MuiSelect-select': {
              padding: '16px 8px',
            },
            '& .MuiSvgIcon-root': {
              color: 'primaryColors.white',
            },
          }}
          renderValue={(value) => {
            return (
              <Box display="flex" alignItems="center" sx={{ width: '100%' }}>
                <CorporateFareIcon fontSize="small" sx={{ marginRight: '12px' }} />
                {accounts?.find((account: Account) => account.id === value)?.name}
              </Box>
            )
          }}
        >
          {accounts?.map((account: Account) => (
            <MenuItem key={account.id} value={account.id}>
              <Link href={`/overview/account/${account.id}`}>
                <Box title={account.name} display="flex" alignItems="center" sx={{ width: '100%' }}>
                  <CorporateFareIcon fontSize="small" sx={{ marginRight: '12px' }} />
                  {account.name}
                </Box>
              </Link>
            </MenuItem>
          ))}
          {showAddAccount && (
            <MenuItem value="add-account" title="Add Organization">
              <Box
                title="Add Organization"
                display="flex"
                alignItems="center"
                sx={{ width: '100%' }}
                onClick={() => setShowOrgModal(true)}
              >
                <AddCircle fontSize="small" sx={{ marginRight: '12px' }} />
                {!collapsed ? 'Add organization' : ''}
              </Box>
            </MenuItem>
          )}
        </Select>
      </FormControl>
      <AddOrgModal open={showOrgModal} onClose={() => setShowOrgModal(false)} />
    </>
  )
}
