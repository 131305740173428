import { useAuth0 } from '@auth0/auth0-react'
import useSWR from 'swr'

import { GET_ACCOUNT } from '../queries'
import { consoleFetcher } from 'common/fetchers/graphql'

export default function useProject(accountId?: string) {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0()
  const { data, error, mutate } = useSWR(
    (isAuthenticated || process.env.NEXT_PUBLIC_BYPASS_AUTH_STATE) && accountId
      ? [GET_ACCOUNT, { accountId: accountId }, { tokenFn: getAccessTokenSilently }]
      : false,
    consoleFetcher,
    { shouldRetryOnError: false }
  )

  return {
    account: data ? data.account : null,
    loading: typeof data === 'undefined' && !error,
    error: error || null,
    mutate,
  }
}
