import { useContext } from 'react'

import RepositoryContext from './RepositoryContext'

type UnleashContext = {
  accountID?: string | string[] | undefined
  planID?: string | string[] | undefined
  userID?: string | string[] | undefined
}

const useFeatureToggle = (
  featureName: string,
  defaultValue = false,
  unleashContext: UnleashContext = { accountID: '0' }
) => {
  const features = useContext(RepositoryContext)
  const toggle = features.find((feat) => feat.name === featureName)

  if (process.env.NEXT_PUBLIC_FEATURE_TOGGLES) {
    const featureToggle = JSON.parse(process.env.NEXT_PUBLIC_FEATURE_TOGGLES)
    if (featureToggle[featureName]) {
      return featureToggle[featureName] === 'true'
    }
  }

  if (!toggle) {
    return defaultValue
  } else if (!toggle.enabled) {
    return false
  } else {
    for (let i = 0; i < toggle.strategies.length; i++) {
      const strategyDef = toggle.strategies[i]
      const strategyImpl = strategyImplRepository.get(strategyDef.name)
      if (strategyImpl.isEnabled(strategyDef.parameters, unleashContext)) {
        return true
      }
    }
    return false
  }
}

const cookieStrategy = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
  isEnabled: (parameters: any, context: any) => {
    const checkIfCookieExists = (value: string) => {
      if (document.cookie.split(';').some((item) => item.trim().startsWith(`${value}=`))) {
        return true
      }
      return false
    }
    const enableIfCookieExists = () => {
      // Capture toggle value/strategy.
      const cookieName = parameters.cookieName

      // Determine whether user has the correct cookie
      return checkIfCookieExists(cookieName)
    }

    return enableIfCookieExists()
  },
}

const accountIDParamProperty = 'accountIds'
const userIDParamProperty = 'userIds'
const planIDParamProperty = 'planIDs'
const accountIDContextProperty = 'accountID'
const userIDContextProperty = 'userID'
const planIDContextProperty = 'planID'

const userIDStrategy = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
  isEnabled: (parameters: any, context: any) => {
    const userID = String(context[userIDContextProperty])
    const userIDsString: string = parameters[userIDParamProperty]
    if (userIDsString) {
      const userIDs = userIDsString.split(',')
      for (const id of userIDs) {
        if (userID === id) {
          return true
        }
      }
    }
    return false
  },
  name: 'userWithId',
}

const accountIDStrategy = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
  isEnabled: (parameters: any, context: any) => {
    const accountID = String(context[accountIDContextProperty])
    const accountIDsString: string = parameters[accountIDParamProperty]
    if (accountIDsString) {
      const accountIDs = accountIDsString.split(',')
      for (const id of accountIDs) {
        if (accountID === id) {
          return true
        }
      }
    }
    return false
  },
  name: 'accountWithId',
}

const planIDStrategy = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
  isEnabled: (parameters: any, context: any) => {
    const planID = String(context[planIDContextProperty])
    const planIDsString: string = parameters[planIDParamProperty]
    if (planIDsString) {
      const planIDs = planIDsString.split(',')
      for (const id of planIDs) {
        if (planID === id) {
          return true
        }
      }
    }
    return false
  },
  name: 'planWithID',
}

const strategyImplRepository = {
  get: (name: string) => {
    switch (name) {
      case 'activeWithCookieName':
        return cookieStrategy
      case userIDStrategy.name:
        return userIDStrategy
      case accountIDStrategy.name:
        return accountIDStrategy
      case planIDStrategy.name:
        return planIDStrategy
      case 'default':
        return {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
          isEnabled: (parameters: any, context: any) => {
            return true
          },
        }
      default:
        return {
          isEnabled: () => {
            return false
          },
        }
    }
  },
}

export default useFeatureToggle
