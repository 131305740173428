import { gql } from 'graphql-request'

export const CREATE_PROJECT = gql`
  mutation CreateProject($name: String!, $accountId: Int!, $planId: ID, $paymentProfileId: ID) {
    createProject(input: { name: $name, accountId: $accountId, planId: $planId, paymentProfileID: $paymentProfileId }) {
      applicationName
      id
    }
  }
`

export const EXTEND_PROJECT_HIBERNATION = gql`
  mutation ExtendProjectHibernation($projectId: String!) {
    extendProjectHibernation(id: $projectId)
  }
`

// CreateAndInitProject

// add creationReason to this query when the backend is updated
export const CREATE_AND_INIT_PROJECT = gql`
  mutation CreateAndInitProject(
    $name: String!
    $accountId: Int!
    $planId: ID
    $paymentProfileId: ID
    $imageName: String!
    $port: Int!
    $credentials: ImageCredentialsInput
    $environmentVariables: [EnvironmentVariable!]
    $command: String
    $args: String
    $resourceRequest: ResourceRequest
    $loStrategy: String
    $couponCodes: [String!]
    $createReason: String
  ) {
    createAndInitProject(
      input: {
        name: $name
        accountId: $accountId
        planId: $planId
        paymentProfileID: $paymentProfileId
        couponCodes: $couponCodes
      }
      imageName: $imageName
      port: $port
      credentials: $credentials
      environmentVariables: $environmentVariables
      command: $command
      args: $args
      resourceRequest: $resourceRequest
      loStrategy: $loStrategy
      createReason: $createReason
    ) {
      applicationName
      id
    }
  }
`

export const CREATE_GITHUB_PROJECT = gql`
  mutation CreateGithubProject(
    $name: String!
    $accountId: String!
    $planId: String!
    $paymentProfileId: String!
    $loStrategy: String
    $templateRepo: String!
    $templateOwner: String!
    $couponCodes: [String!]
  ) {
    createGithubProject(
      name: $name
      accountID: $accountId
      planID: $planId
      paymentProfileID: $paymentProfileId
      loStrategy: $loStrategy
      templateRepo: $templateRepo
      templateOwner: $templateOwner
      couponCodes: $couponCodes
    ) {
      applicationName
      id
    }
  }
`

export const GET_PROJECTS = gql`
  query GetProjects($accountId: ID!) {
    account(id: $accountId) {
      applications {
        name
        id
        is_kei
        planId
        hibernationExpirationDate
        hibernated
        environments {
          id
          name
          keiId
          domains {
            name
          }
          clusterStates {
            cluster
            endpoint {
              displayName
              endpointName
              country
              locationCode
              provider
              region
            }
            trafficDirectionDesired
            isHealthy
            isTrafficDirected
          }
        }
        annotations
        createState
      }
    }
  }
`

export const GET_PENDING_PROJECTS = gql`
  query GetPendingProjects($accountId: ID!) {
    pendingProjects(accountID: $accountId) {
      id
      planID
      type
      imageName
      port
      loStrategy
      envVars {
        name
        value
      }
      resourceRequest {
        memory
        cpu
      }
      command
      commandArgs
      couponCode
      creationReason
      created
    }
  }
`

export const GET_PROJECT_PLANS = gql`
  query GetProjectPlans {
    projectPlans {
      id
      displayName
      isActive
      isFree
    }
  }
`

export const GET_PROJECT_PLAN = gql`
  query GetProjectPlan($id: ID!) {
    projectPlan(id: $id) {
      id
      displayName
      isActive
      isFree
    }
  }
`

export const DELETE_PROJECT = gql`
  mutation DeleteProject($projectId: Int!) {
    deleteProject(input: { projectId: $projectId }) {
      isDeleted
    }
  }
`

export const EDIT_PROJECT_NAME = gql`
  mutation RenameProject($projectId: Int!, $newName: String!) {
    renameProject(input: { projectId: $projectId, newName: $newName }) {
      isRenamed
    }
  }
`

export const GET_LOCATION_OPTIMIZER = gql`
  query GetProject($accountId: ID!) {
    account(id: $accountId) {
      applications {
        id
        environments {
          locationOptimizerStrategy {
            data
            lastUpdatedAt
          }
        }
      }
    }
  }
`

export const UPDATE_LO_STRATEGY = gql`
  mutation UpdateLoStrategy($projectId: String!, $strategy: String!) {
    updateLOStrategy(projectID: $projectId, strategy: $strategy)
  }
`

export const GET_PAYMENT_METHODS = gql`
  query GetPaymentMethods($accountId: ID!) {
    paymentProfile(accountID: $accountId) {
      cardType
      maskedCardNumber
      id
    }
  }
`

export const ADD_PAYMENT_METHOD = gql`
  mutation AddPaymentMethod($accountId: ID!, $chargifyToken: String!) {
    createPaymentProfile(accountID: $accountId, chargifyToken: $chargifyToken) {
      cardType
      maskedCardNumber
      id
    }
  }
`

export const UPDATE_PROJECT_PLAN = gql`
  mutation UpdateProjectPlan(
    $projectId: String!
    $planId: String!
    $paymentProfileId: String
    $couponCodes: [String!]
  ) {
    updateProjectPlan(
      projectID: $projectId
      planID: $planId
      paymentProfileID: $paymentProfileId
      couponCodes: $couponCodes
    )
  }
`

export const VALIDATE_DOCKER_IMAGE = gql`
  query validateDockerImage($image: String!, $credentials: ImageCredentialsInput) {
    validateDockerImage(image: $image, credentials: $credentials)
  }
`
export const ADD_COUPON_CODE = gql`
  mutation AddProjectCouponCodes($projectId: String!, $couponCodes: [String!]!) {
    addProjectCouponCodes(projectID: $projectId, couponCodes: $couponCodes)
  }
`
export const VALIDATE_COUPON_CODE = gql`
  query verifyCouponCode($couponCode: String!) {
    verifyCouponCode(couponCode: $couponCode)
  }
`
export const APPLY_PROJECT_YAML = gql`
  mutation ApplyProjectYaml($email: String!, $projectID: Int!, $yaml: String!) {
    deployYAML(email: $email, projectID: $projectID, yaml: $yaml) {
      success
      message
    }
  }
`
