import { gql } from 'graphql-request'

export const GET_ACCOUNT = gql`
  query GetAccount($accountId: ID!) {
    account(id: $accountId) {
      id
      name
      type
      tenantID
      approved
    }
  }
`

export const CREATE_ORG = gql`
  mutation CreateOrg($tenantID: String!, $name: String!, $type: String!) {
    createAccount(tenantID: $tenantID, name: $name, type: $type)
  }
`
