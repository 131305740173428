import { MouseEventHandler, forwardRef } from 'react'

import CloseIcon from '@mui/icons-material/Close'
import type { BoxProps, ModalProps, SxProps, Theme } from '@mui/material'
import { IconButton, Typography } from '@mui/material'
import { alpha } from '@mui/material/styles'
import { Box } from '@mui/system'

type BasicModalHeaderTitle = string | JSX.Element

interface BasicModalHeaderProps extends BoxProps {
  closeButtonDisabled: boolean
  icon?: JSX.Element
  headerTitle?: BasicModalHeaderTitle
  onClose?: ModalProps['onClose']
}

export interface BasicModalProps extends BoxProps {
  /**
   * Hides the close button
   */
  closeButtonDisabled?: boolean
  /**
   * The icon to display in the modal header.
   */
  headerIcon?: JSX.Element
  /**
   * The text to display in the modal header.
   */
  headerTitle?: BasicModalHeaderTitle
  /**
   * The function that is called when the close button is clicked.
   */
  onClose?: ModalProps['onClose']
}

const BasicModalRootStyles: SxProps<Theme> = {
  backgroundColor: 'primaryColors.white',
  boxShadow: (theme) => `0px 8px 16px ${alpha(theme.palette.primaryColors.modalBlack, 0.65)}`,
  display: 'flex',
  flexDirection: 'column',
  left: '50%',
  position: 'absolute',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  borderRadius: '4px',
  minHeight: '200px',
  minWidth: {
    xs: '100%',
    sm: 'inherit',
  },
}

const BasicModalHeaderStyles: SxProps<Theme> = {
  alignItems: 'center',
  display: 'flex',
  height: '80px',
  padding: '16px 24px',
}

function BasicModalHeader({ closeButtonDisabled, icon, headerTitle, onClose, sx }: BasicModalHeaderProps) {
  const handleCloseIconClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    onClose && onClose(e, 'escapeKeyDown')
  }

  return (
    <Box
      sx={[
        {
          '&.MuiBox-root': {
            ...BasicModalHeaderStyles,
          },
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {icon && <Box sx={{ marginRight: '16px' }}>{icon}</Box>}
      <Typography variant="h6">{headerTitle}</Typography>
      {!closeButtonDisabled && (
        <IconButton
          aria-label="close"
          onClick={handleCloseIconClick}
          sx={{
            marginLeft: 'auto',
          }}
        >
          <CloseIcon />
        </IconButton>
      )}
    </Box>
  )
}

/**
 * Basic modal based off of the Section Pattern Library.
 */
const BasicModal = forwardRef<HTMLDivElement, BasicModalProps>(
  ({ children, closeButtonDisabled = false, headerIcon, headerTitle, onClose, sx }, ref) => (
    <Box
      ref={ref}
      sx={[
        {
          '&.MuiBox-root': {
            ...BasicModalRootStyles,
          },
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      tabIndex={-1}
    >
      <BasicModalHeader
        closeButtonDisabled={closeButtonDisabled}
        icon={headerIcon}
        headerTitle={headerTitle}
        onClose={onClose}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: '1',
          padding: '24px',
          borderRadius: '0 0 4px 4px',
          backgroundColor: 'primaryColors.modalWhite',
        }}
      >
        {children}
      </Box>
    </Box>
  )
)
BasicModal.displayName = 'BasicModal'

export default BasicModal
