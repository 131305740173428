import React, { useState } from 'react'

import { SectionUserContext } from '../contexts/SectionUserContext'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const SectionUserProvider = ({ children }) => {
  const [selectedAccount, setSelectedAccount] = useState('')
  const [sectionUser, setSectionUser] = useState({})
  const [selectedTheme, setSelectedTheme] = useState('1')

  return (
    <SectionUserContext.Provider
      value={{
        selectedAccount,
        setSelectedAccount,
        sectionUser,
        setSectionUser,
        selectedTheme,
        setSelectedTheme,
      }}
    >
      {children}
    </SectionUserContext.Provider>
  )
}
export default SectionUserProvider
