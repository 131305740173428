/* eslint-disable no-console */

/* eslint-disable react/jsx-filename-extension */

/* eslint-disable react/destructuring-assignment */
import React, { Component, ErrorInfo } from 'react'

import { Box, Button, Typography } from '@mui/material'
import LogRocket from 'logrocket'

interface State {
  hasError: boolean
  error?: Error
  errorInfo?: ErrorInfo
}

class ErrorBoundary extends Component<{}, State> {
  constructor(props: {}) {
    super(props)
    this.state = {
      hasError: false,
      error: undefined,
      errorInfo: undefined,
    }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    console.error(error)
    console.error(errorInfo)
    LogRocket.captureException(error)
    LogRocket.captureMessage(errorInfo.componentStack)
    this.setState({ hasError: true, errorInfo, error })
  }

  navigateBack = () => window.history.back()

  render() {
    if (this.state.hasError) {
      return (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: 1000,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(255, 255, 255, 0.5)',
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant="h6">
              Oops, something went wrong. But don&apos;t worry - it&apos;s not your fault.
            </Typography>
            <Button onClick={this.navigateBack} type="button" sx={{ marginTop: '12px' }}>
              Go back
            </Button>
            {process.env.NODE_ENV !== 'production' && (
              <>
                <div>{this.state?.error?.message}</div>
                <pre>{this.state?.errorInfo?.componentStack}</pre>
              </>
            )}
          </Box>
        </Box>
      )
    }
    return this.props.children
  }
}

export default ErrorBoundary
