import { Auth0ContextInterface } from '@auth0/auth0-react'
import { RequestDocument, request } from 'graphql-request'
import type { Variables } from 'graphql-request'

interface ConsoleHeaders {
  'section-user'?: string
  'section-capabilities'?: string
  'section-token'?: string
  'true-client-ip': string
}

// There are more things available on this error type if needed, you can use the debugger to explore them if we want more data
interface GraphqlRequestError extends Error {
  response: GrapqlRequestErrorResponse
}

interface GrapqlRequestErrorResponse {
  errors: Array<GqlError>
  status: number
}

interface GqlError {
  message: string
}

const _console_headers: ConsoleHeaders = {
  'true-client-ip': '127.0.0.1',
}
// headers for authenticating in local development
if (process.env.NEXT_PUBLIC_BYPASS_AUTH_STATE && process.env.NEXT_PUBLIC_SECTION_USER)
  _console_headers['section-user'] = process.env.NEXT_PUBLIC_SECTION_USER
if (process.env.NEXT_PUBLIC_BYPASS_AUTH_STATE && process.env.NEXT_PUBLIC_SECTION_CAPABILITIES)
  _console_headers['section-capabilities'] = process.env.NEXT_PUBLIC_SECTION_CAPABILITIES
if (process.env.NEXT_PUBLIC_BYPASS_AUTH_STATE && process.env.NEXT_PUBLIC_SECTION_TOKEN)
  _console_headers['section-token'] = process.env.NEXT_PUBLIC_SECTION_TOKEN

export async function consoleFetcher(
  document: RequestDocument,
  variables: Variables,
  args: { tokenFn: Auth0ContextInterface['getAccessTokenSilently'] }
) {
  const authorizationHeaders: { Authorization?: string } = {}
  let authorizationHeader = ''
  try {
    authorizationHeader = `Bearer ${await args.tokenFn()}`
  } catch (e) {
    // TODO: Add error logging
  }
  if (authorizationHeader) {
    authorizationHeaders.Authorization = authorizationHeader
  }
  const developmentHeaders =
    process.env.NODE_ENV !== 'production'
      ? {
          ..._console_headers,
        }
      : {}

  return await request(`${process.env.NEXT_PUBLIC_CONSOLE_BACKEND_ADDRESS}`, document, variables, {
    ...authorizationHeaders,
    ...developmentHeaders,
  }).catch((e) => {
    const err = e as GraphqlRequestError

    if (err.response?.errors?.length > 0) {
      throw new Error(err.response?.errors[0].message)
    }
    if (err.response?.status != 200) {
      throw new Error('There was a problem communicating with the server. Status Code: ' + err.response?.status)
    }

    throw e
  })
}
