import { useContext, useState } from 'react'

import { useRouter } from 'next/router'

import { yupResolver } from '@hookform/resolvers/yup'
import { Modal } from '@mui/material'
import { Box, Button, CircularProgress, TextField } from '@mui/material'
import { ModalProps } from '@mui/material'
import { useForm } from 'react-hook-form'
import { InferType } from 'yup'

import { createOrganizationSchema } from '../schemas'
import BasicModal from 'common/components/ui/modals/BasicModal'
import { SectionUserContext } from 'common/contexts/SectionUserContext'
import useAccount from 'modules/accounts/hooks/useAccount'
import useCreateOrg from 'modules/accounts/hooks/useCreateOrg'
import useBannerNotification from 'modules/notifications/banners/useBannerNotification'
import useSectionUser from 'modules/user/hooks/useSectionUser'

interface AddOrgModalProps {
  onClose: ModalProps['onClose']
  open: ModalProps['open']
}

export default function AddOrgModal({ onClose, open, ...props }: AddOrgModalProps) {
  const { push } = useBannerNotification()
  const router = useRouter()
  const { mutate: mutateUser } = useSectionUser()
  const { accountId } = router.query
  const { account } = useAccount(accountId as string)
  const { createOrg, resetCreateOrg } = useCreateOrg()
  const { setSelectedAccount } = useContext(SectionUserContext)

  const [OrganizationCreateState, setOrganizationCreateState] = useState({
    isCreating: false,
    isCreated: false,
    name: '',
  })

  const { formState, handleSubmit, register } = useForm<InferType<typeof createOrganizationSchema>>({
    defaultValues: {
      name: '',
    },
    resolver: yupResolver(createOrganizationSchema),
  })

  const { errors } = formState

  const onSubmit = handleSubmit(async (data) => {
    setOrganizationCreateState({
      ...data,
      isCreating: true,
      isCreated: false,
    })

    try {
      const { success, orgId, err } = await createOrg(data, account?.tenantID || '1', account?.type || 'default')

      if (!success && !orgId) {
        throw new Error(err || 'We could not create the organization. Please try again later.')
      }

      push({
        message: 'Organization created successfully',
        variant: 'success',
      })

      setOrganizationCreateState({
        name: '',
        isCreating: false,
        isCreated: true,
      })

      // need to set the selected account to the new org
      mutateUser()
      setSelectedAccount(orgId as string)
      resetCreateOrg()
      onClose && onClose({}, 'escapeKeyDown')

      router.push(`/overview/account/${orgId}/`)
    } catch (error) {
      const { message } = error as Error
      push({
        message: message,
        variant: 'error',
      })

      setOrganizationCreateState({
        ...OrganizationCreateState,
        isCreating: false,
        isCreated: false,
      })
    }
  })

  return (
    <Modal onClose={onClose} open={open} {...props}>
      <BasicModal
        closeButtonDisabled={false}
        headerTitle="Add an organization"
        onClose={onClose}
        sx={{
          '&.MuiBox-root': {
            minWidth: {
              xs: '100%',
              sm: '200px',
            },
            maxWidth: '400px',
            width: '100%',
          },
        }}
      >
        <>
          <form onSubmit={onSubmit}>
            <Box sx={{ alignItems: 'start', display: 'flex', flexDirection: 'column', marginBottom: '16px' }}>
              <TextField
                id="name"
                error={Boolean(errors.name)}
                helperText={errors.name && errors.name.message}
                label="Organization name"
                autoFocus
                sx={{ marginBottom: '16px', width: '100%' }}
                variant="filled"
                {...register('name')}
              />
            </Box>
            <Box sx={{ alignItems: 'center', display: 'flex' }}>
              <Box sx={{ position: 'relative', width: '100%' }}>
                <Button
                  color="primary"
                  variant="contained"
                  sx={{
                    width: '100%',
                  }}
                  type="submit"
                  disabled={OrganizationCreateState.isCreating}
                  data-cy="org-create-confirm"
                  id="org-create-confirm"
                >
                  Create organization
                </Button>
                {OrganizationCreateState.isCreating && (
                  <CircularProgress
                    size={24}
                    sx={{
                      color: 'primaryColors.alertRed',
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      marginTop: '-12px',
                      marginLeft: '-12px',
                    }}
                  />
                )}
              </Box>
            </Box>
          </form>
        </>
      </BasicModal>
    </Modal>
  )
}
