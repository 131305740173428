import React from 'react'

import RepositoryContext from './RepositoryContext'
import useFetchToggles from './useFetchToggles'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const UnleashProvider = ({ children }) => {
  // TODO: potentially call to register at api/client/register
  const features = useFetchToggles('/featuretoggle/api/client/features')

  return <RepositoryContext.Provider value={features}>{children}</RepositoryContext.Provider>
}
export default UnleashProvider
