import { useAuth0 } from '@auth0/auth0-react'
import { GraphQLError } from 'graphql'
import useSwr from 'swr'

import { CONSOLE_GRAPHQL_NOT_AUTHED_ERROR } from '../constants'
import { GET_CURRENT_USER_INFO } from '../queries'
import { consoleFetcher } from 'common/fetchers/graphql'

export default function useSectionUser() {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0()
  const { data, error, mutate } = useSwr(
    isAuthenticated || process.env.NEXT_PUBLIC_BYPASS_AUTH_STATE
      ? [GET_CURRENT_USER_INFO, null, { tokenFn: getAccessTokenSilently }]
      : false,
    consoleFetcher,
    {
      revalidateOnFocus: false,
      shouldRetryOnError: false,
    }
  )
  let returnedError = null

  if (error) {
    if (error.response && error.response.errors) {
      const returnedErrorArray = error.response.errors.filter(
        (error: GraphQLError) => error.message !== CONSOLE_GRAPHQL_NOT_AUTHED_ERROR
      )
      if (returnedErrorArray.length > 0) returnedError = returnedErrorArray
    } else {
      returnedError = error
    }
  }

  return {
    user: data?.getCurrentUser,
    loading: typeof data === 'undefined' && !error,
    error: returnedError,
    mutate,
  }
}
