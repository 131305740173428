import LogRocket from 'logrocket'
import mixpanelLib, { Dict } from 'mixpanel-browser'

let mixpanel: typeof mixpanelLib | undefined

/**
 * We want to be able to use Mixpanel in a simple way throughout
 * our codebase without having to check an environment variable
 * or checking to see if it's already been initialized.
 *
 * This conditional export allows us to import `mixpanel` defined
 * here and use optional chaining to call mixpanel in a clean
 * and simple way.
 *
 * E.g.:
 * ```
 * import mixpanel from 'modules/lib/mixpanel'
 *
 * mixpanel?.track('sample_event')
 * ```
 */
if (process.env.NEXT_PUBLIC_MIXPANEL_TOKEN) {
  try {
    mixpanelLib.init(process.env.NEXT_PUBLIC_MIXPANEL_TOKEN, {
      api_host: '/mp',
      persistence: 'localStorage',
      secure_cookie: true,
      ignore_dnt: true,
    })
  } catch (e) {
    console.log('failed to init mixpanel\n', e)
    LogRocket.captureException(e as Error)
  }
  mixpanel = mixpanelLib
} else {
  mixpanel = undefined
}

const actions = {
  identify: (id: string | undefined) => {
    mixpanel?.identify(id)
  },
  alias: (id: string) => {
    mixpanel?.alias(id)
  },
  track: (name: string, props?: Dict | undefined) => {
    mixpanel?.track(name, props)
  },
  people: {
    set: (props: Dict) => {
      mixpanel?.people.set(props)
    },
  },
}

export default actions
