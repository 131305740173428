const CONSOLE_GRAPHQL_NOT_AUTHED_ERROR = 'Need an authorized user to do this query'
const CONSOLE_GRAPHQL_CREATE_USER_ACCOUNT_DISABLED_ERROR = 'create user and account is disabled currently'
const CONSOLE_AUTH0_AUTHENTICATION_ERROR_MESSAGE = '"We had an issue authenticating your account'
const CONSOLE_GRAPHQL_USER_QUEUE_MESSAGE =
  'Thanks, we have put your request in a queue and you will receive an email notification when your project is ready to be created.'

export {
  CONSOLE_GRAPHQL_NOT_AUTHED_ERROR,
  CONSOLE_GRAPHQL_CREATE_USER_ACCOUNT_DISABLED_ERROR,
  CONSOLE_AUTH0_AUTHENTICATION_ERROR_MESSAGE,
  CONSOLE_GRAPHQL_USER_QUEUE_MESSAGE,
}
