import { ReactElement } from 'react'

import NotificationsIcon from '@mui/icons-material/Notifications'
import { Box, Button, Divider, Grid } from '@mui/material'

const NotificationButton = ({ text, icon }: { text: string; icon: ReactElement }) => {
  return (
    <Button
      variant="outlined"
      color="secondary"
      size="small"
      endIcon={icon}
      sx={{
        fontSize: 12,
        padding: '2px 5px',
        marginLeft: 1,
      }}
    >
      {text}
    </Button>
  )
}

export default function NotificationCenter() {
  return (
    <Box flex={0}>
      <Grid
        container
        alignItems="center"
        direction="row"
        spacing={1}
        sx={{
          width: '100%',
          padding: 1,
          '& hr': {
            mx: 2,
            borderColor: 'rgba(0, 0, 0, 0.2)',
          },
        }}
      >
        <Grid item sx={{ marginLeft: 'auto' }}>
          {/* <NotificationButton text="Help" icon={<QuestionMarkIcon fontSize="small" />} />
          <NotificationButton text="Feedback for us?" icon={<ModeCommentIcon fontSize="small" />} /> */}
          <NotificationButton text="Notifications" icon={<NotificationsIcon fontSize="small" />} />
        </Grid>
      </Grid>
      <Divider />
    </Box>
  )
}
