import { useContext, useEffect, useRef } from 'react'
import type { ReactElement, ReactNode } from 'react'

import type { NextPage } from 'next'
import type { AppProps } from 'next/app'
import { useRouter } from 'next/router'

import { Auth0Provider, useAuth0 } from '@auth0/auth0-react'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import LogRocket from 'logrocket'

import ErrorBoundary from 'common/components/errors/ErrorBoundary'
import UnleashProvider from 'common/components/featuretoggle/UnleashProvider'
import { getLayout } from 'common/components/layouts/AppLayout'
import AppMeta from 'common/components/layouts/metas/AppMeta'
import LoadingOverlay from 'common/components/util/LoadingOverlay'
import { SectionUserContext } from 'common/contexts/SectionUserContext'
import SectionUserProvider from 'common/providers/SectionUserProvider'
import SelectedProjectEnvironmentProvider from 'common/providers/SelectedProjectEnvironmentProvider'
import akamaiTheme from 'common/theme/akamaiTheme'
import lumenTheme from 'common/theme/lumenTheme'
import sectionTheme from 'common/theme/sectionTheme'
import mixpanel from 'modules/lib/mixpanel'
import { BannerProvider } from 'modules/notifications/banners/BannerProvider'
import VerificationErrorOverlay from 'modules/user/VerificationErrorOverlay'
import AuthErrorOverlay from 'modules/user/components/AuthErrorOverlay'
import {
  CONSOLE_AUTH0_AUTHENTICATION_ERROR_MESSAGE,
  CONSOLE_GRAPHQL_CREATE_USER_ACCOUNT_DISABLED_ERROR,
  CONSOLE_GRAPHQL_USER_QUEUE_MESSAGE,
} from 'modules/user/constants'
import useSectionUser from 'modules/user/hooks/useSectionUser'

export type GetAppLayout = (page: ReactElement) => ReactNode

export type AppPage<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: GetAppLayout
  isPublic?: boolean
}

type Props<P = {}> = AppProps<P> & {
  Component: AppPage<P>
}

const defaultGetLayout: GetAppLayout = getLayout

function getCookie(name: string) {
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${name}=`)
  if (parts.length === 3) return parts?.pop()?.split(';').shift()
}

function App({ Component, pageProps }: Props) {
  const { isLoading: authLoading, error: authError, isAuthenticated, loginWithRedirect, user } = useAuth0()
  const { loading: sectionUserLoading, user: sectionUser, error: sectionUserError } = useSectionUser()
  const authRedirectCalled = useRef(false)
  const router = useRouter()
  const { accountId, refererPage } = router.query
  const { selectedAccount, setSelectedAccount, setSectionUser, selectedTheme, setSelectedTheme } =
    useContext(SectionUserContext)

  useEffect(() => {
    if (refererPage) {
      typeof localStorage !== 'undefined' && localStorage.setItem('refererPage', refererPage as string)
    }
  }, [refererPage])

  useEffect(() => {
    if (sectionUser?.accounts) {
      setSectionUser(sectionUser)
    }
  }, [sectionUser, setSectionUser])

  useEffect(() => {
    if (
      (sectionUser?.accounts && !selectedAccount) ||
      (sectionUser?.accounts && selectedAccount && accountId && selectedAccount !== accountId)
    ) {
      // Set contexts
      const defaultAccount = sectionUser?.accounts?.find(
        (account: { name: string; id: string; type: string; tenantID: string }) => account.id === accountId
      )

      setSelectedTheme(defaultAccount ? defaultAccount.tenantID : sectionUser?.accounts[0]?.tenantID)
      setSelectedAccount(defaultAccount ? defaultAccount.id : sectionUser?.accounts[0]?.id)
    }
  }, [sectionUser, accountId, setSelectedAccount, selectedAccount, setSelectedTheme])

  useEffect(() => {
    if (sectionUser && accountId) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (dataLayer) {
        // push user id to GTM
        // TODO get typescript to accept dataLayer as valid
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        dataLayer?.push({
          user_id: sectionUser.id,
          user_email: sectionUser.email,
          user_first_name: sectionUser.first_name,
          user_last_name: sectionUser.last_name,
          user_signedUp: sectionUser.signedUp,
          user_createdAt: Math.floor(new Date(sectionUser.createdAt).getTime() / 1000),
          event: 'user_properties_set',
          accountId,
          account_id: accountId,
        })
        if (sectionUser.signedUp) {
          //user was just created so fire a special GTM event
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          dataLayer?.push({ event: 'sign_up' })
        }
      }

      LogRocket.identify(sectionUser.email)
      mixpanel?.identify(sectionUser.id)
      mixpanel?.people.set({
        $email: sectionUser.email,
        $first_name: sectionUser.first_name,
        $last_name: sectionUser.last_name,
        company: sectionUser.company_name,
        accounts: sectionUser.accounts.map((account: { id: string }) => account.id),
      })
    }
  }, [sectionUser, accountId, setSelectedAccount, setSectionUser])

  // push a event to datalayer when the user first logs in
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (dataLayer && isAuthenticated && !getCookie('section_logged_in')) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      dataLayer.push({ event: 'login' })
      document.cookie = `section_logged_in=true;path=/;Session;domain=${
        process.env.NODE_ENV === 'production' ? 'console.section.io' : 'localhost'
      }`
    }
  }, [isAuthenticated, user])

  // Get page layout defined at the page level, if available;
  // otherwise use default layout
  const getLayout = Component.getLayout ?? defaultGetLayout

  // Get the page (or overlay if loading or errored)
  let pageComponent = null
  // page is not public but authentication state is still loading
  // the `loading` condition is a carry-over from legacy
  // data-fetching strategies. Will show the loading overlay if a legacy
  // component makes a data request
  if (
    (!Component.isPublic && authLoading) ||
    ((isAuthenticated || process.env.NEXT_PUBLIC_BYPASS_AUTH_STATE) &&
      (sectionUserLoading || (!sectionUserError && !sectionUser)))
  )
    pageComponent = <LoadingOverlay />
  else if (authError) {
    console.log('authError', authError)
    if (authError?.message.includes('verify your email')) {
      pageComponent = <VerificationErrorOverlay errMessage={authError.message} />
    } else {
      pageComponent = <AuthErrorOverlay messageTitle={CONSOLE_AUTH0_AUTHENTICATION_ERROR_MESSAGE} />
    }
  } else if (sectionUserError && sectionUserError[0]?.message === CONSOLE_GRAPHQL_CREATE_USER_ACCOUNT_DISABLED_ERROR) {
    pageComponent = <AuthErrorOverlay messageTitle={CONSOLE_GRAPHQL_USER_QUEUE_MESSAGE} />
  } else if (isAuthenticated && !user?.email_verified && user?.['https://section.io/logins_count'] > 1) {
    pageComponent = <AuthErrorOverlay messageTitle={CONSOLE_AUTH0_AUTHENTICATION_ERROR_MESSAGE} />
  }

  // page is either public or the user is confirmed to be authenticated
  else if (Component.isPublic === true || isAuthenticated || process.env.NEXT_PUBLIC_BYPASS_AUTH_STATE) {
    pageComponent = getLayout(<Component {...pageProps} />)
  } else {
    // page is not public and the user is not authenticated
    // Check if this is already called so that we don't fire multiple mixpanel events
    if (!authRedirectCalled.current) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      dataLayer?.push({
        event: 'authentication_redirect',
      })
      if (router.pathname === '/signup' || router.pathname === '/signup/') {
        // check for signup route before redirect to login page
        loginWithRedirect({
          appState: {
            returnTo: typeof window !== 'undefined' && window.location.href,
            target: window.location.search,
          },
          screen_hint: 'signup',
        })
      } else {
        loginWithRedirect({
          appState: {
            returnTo: typeof window !== 'undefined' && window.location.href,
            target: window.location.search,
          },
        })
      }
      authRedirectCalled.current = true
    }
    pageComponent = <LoadingOverlay />
  }

  let theme = sectionTheme
  if (selectedTheme === '6') {
    theme = lumenTheme
  } else if (selectedTheme === '19') {
    theme = akamaiTheme
  }

  return (
    <>
      <AppMeta />
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ErrorBoundary>
          <BannerProvider autoHideDuration={8500}>{pageComponent}</BannerProvider>
        </ErrorBoundary>
      </ThemeProvider>
    </>
  )
}

export default function AppProvider(props: Props) {
  const router = useRouter()
  const { push } = router

  if (process.env.NEXT_PUBLIC_LOGROCKET_PROJECT && process.env.NEXT_PUBLIC_LOGROCKET_PROJECT !== '') {
    LogRocket.init(process.env.NEXT_PUBLIC_LOGROCKET_PROJECT, {
      network: {
        requestSanitizer: (request) => {
          if (request && request?.url?.includes('graphql') && request.body != undefined && request.body != '') {
            // keep at least the OperationName from the requests
            const body: string = request.body
            request.body = undefined
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const bodyjson: any = JSON.parse(body)
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const sanitizedBody: any = {}
            sanitizedBody['operationName'] = bodyjson['operationName']
            request.body = JSON.stringify(sanitizedBody)
          } else {
            request.body = undefined
          }
          // reomve body and headers from logging to be extra safe with regards to compliance. We can add back with Security team approval
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const headers: any = {}
          if (request.headers.dnt) {
            headers['dnt'] = request.headers.dnt
          }
          request.headers = headers
          return request
        },
        responseSanitizer: (response) => {
          response.body = undefined
          response.headers = {}
          return response
        },
      },
      shouldCaptureIP: false,
    })
  }
  return (
    <Auth0Provider
      audience={process.env.NEXT_PUBLIC_AUTH_AUDIENCE || ''}
      clientId={process.env.NEXT_PUBLIC_AUTH_CLIENT_ID || ''}
      domain={process.env.NEXT_PUBLIC_AUTH_DOMAIN || ''}
      onRedirectCallback={(appState) => {
        push(appState?.returnTo || window.location.origin)
      }}
      redirectUri={typeof window !== 'undefined' ? window.location.origin : ''}
    >
      <SectionUserProvider>
        <SelectedProjectEnvironmentProvider>
          <UnleashProvider>
            <App {...props} />
          </UnleashProvider>
        </SelectedProjectEnvironmentProvider>
      </SectionUserProvider>
    </Auth0Provider>
  )
}
