import { ReactElement, ReactNode, useContext, useState } from 'react'

import Image from 'next/image'

import { useAuth0 } from '@auth0/auth0-react'
import { ArrowBack, ArrowForward, MonitorHeart } from '@mui/icons-material'
import ApiIcon from '@mui/icons-material/Api'
import AppsIcon from '@mui/icons-material/Apps'
import ArticleIcon from '@mui/icons-material/Article'
import ContactSupportIcon from '@mui/icons-material/ContactSupport'
import LogoutIcon from '@mui/icons-material/Logout'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import SettingsIcon from '@mui/icons-material/Settings'
import {
  AppBar,
  Box,
  Drawer,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Tooltip,
} from '@mui/material'

import useFeatureToggle from '../featuretoggle/useFeatureToggle'
import NotificationCenter from '../navigation/NotificationCenter'
import akamaiLogo from 'assets/vectors/Akamai-GlobalCluster-Logo-1.png'
import DiscordIcon from 'assets/vectors/icons/dark/Discord.svg'
import logoSmall from 'assets/vectors/logos/LogoMark.svg'
import logo from 'assets/vectors/logos/White.svg'
import lumenLogo from 'assets/vectors/lumen-logo.svg'
import lumenLogoSmall from 'assets/vectors/lumen.svg'
import MaterialNextLink from 'common/components/router/MaterialNextLink'
import { SectionUserContext } from 'common/contexts/SectionUserContext'
import AccountSwitcher from 'modules/accounts/components/AccountSwitcher'
import useAccount from 'modules/accounts/hooks/useAccount'
import useIsPayingKEIAccount from 'modules/projects/hooks/useIsPayingKEIAccount'

type GetAppLayout = (page: ReactElement, hideNav?: boolean) => ReactNode

export interface AppLayoutProps extends React.HTMLAttributes<HTMLElement> {
  hideNav?: boolean
}

interface ListItemProps {
  list: {
    icon: ReactElement
    text: string
    link?: string
    openInNewTab?: boolean
    action?: () => void
  }
  collapsed: boolean
}

const InternalLinkListItemComponent = ({ collapsed, list }: ListItemProps) => {
  const RenderedListItemButton = () => {
    if (collapsed)
      return (
        <Tooltip title={list.text} arrow placement="right">
          <ListItemIcon>{list.icon}</ListItemIcon>
        </Tooltip>
      )

    return (
      <>
        <ListItemIcon>{list.icon}</ListItemIcon>
        <ListItemText primary={list.text} />
      </>
    )
  }

  if (list.link) {
    return (
      <MaterialNextLink
        href={list.link}
        sx={{
          color: 'primaryColors.white',
          '&:hover': {
            textDecoration: 'none',
            color: 'primaryColors.actionGrey',
          },
        }}
      >
        <ListItem key={list.text} title={list.text}>
          <ListItemButton>
            <RenderedListItemButton />
          </ListItemButton>
        </ListItem>
      </MaterialNextLink>
    )
  }
  return (
    <ListItem key={list.text} onClick={list.action}>
      <ListItemButton>
        <RenderedListItemButton />
      </ListItemButton>
    </ListItem>
  )
}

const ExternalLinkListItemComponent = ({ list, collapsed }: ListItemProps) => {
  const RenderedListItemButton = () => {
    if (collapsed)
      return (
        <Tooltip title={list.text} arrow placement="right">
          <ListItemIcon>{list.icon}</ListItemIcon>
        </Tooltip>
      )

    return (
      <>
        <ListItemIcon>{list.icon}</ListItemIcon>
        <ListItemText primary={list.text} />
        {list.openInNewTab && (
          <ListItemIcon sx={{ color: 'rgba(255, 255, 255, .2) !important' }}>
            <OpenInNewIcon />
          </ListItemIcon>
        )}
      </>
    )
  }

  return (
    <Link
      href={list.link}
      target="_blank"
      rel="noopener"
      sx={{
        color: 'primaryColors.white',
        '&:hover': {
          textDecoration: 'none',
          color: 'primaryColors.actionGrey',
        },
      }}
    >
      <ListItem key={list.text} onClick={list.action} title={list.text}>
        <ListItemButton>
          <RenderedListItemButton />
        </ListItemButton>
      </ListItem>
    </Link>
  )
}

const AppLayout = ({ children, hideNav }: AppLayoutProps) => {
  const { selectedAccount: accountId, selectedTheme } = useContext(SectionUserContext)

  const shouldShowNotificationsCenter = useFeatureToggle('console.shouldShowNotificationsCenter', false, {
    accountID: accountId,
  })

  const shouldShowDiscordLink = useFeatureToggle('console.shouldShowDiscordLink', false, {
    accountID: accountId,
  })

  const shouldShowSupportLinkFreeUsers = useFeatureToggle('console.shouldShowSupportLinkFreeUsers', false, {
    accountID: accountId,
  })

  const shouldShowCollapsibleSideMenu = useFeatureToggle('console.shouldShowCollapsibleSideMenu', false, {
    accountID: accountId,
  })

  const sideMenuWidth = 360
  const collapsedMenuWidth = 90

  const [collapsed, setCollapsedSideMenu] = useState(false)
  const [drawerWidth, setDrawerWidth] = useState(sideMenuWidth)
  const { isPayingAccount, projectsLoading } = useIsPayingKEIAccount(accountId as string)
  const { account } = useAccount(accountId as string)

  const { logout } = useAuth0()

  let logosrc = logo
  if (selectedTheme === '6') {
    logosrc = lumenLogo
  } else if (selectedTheme === '19') {
    logosrc = akamaiLogo
  }

  if (hideNav) {
    return <>{children}</>
  }

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <AppBar position="fixed" sx={{ width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px` }}></AppBar>
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
              backgroundColor: 'primaryColors.modalBlack',
              color: 'primaryColors.white',
            },
          }}
          variant="permanent"
          anchor="left"
        >
          <Toolbar sx={{ p: 4 }}>
            <Box sx={{ maxWidth: '150px' }}>
              {!collapsed && <Image src={logosrc} alt="Section" title="Section" />}
              {collapsed && (
                <Image
                  src={selectedTheme === '6' ? lumenLogoSmall : logoSmall}
                  alt="Section"
                  title="Section"
                  height={150}
                />
              )}
            </Box>
          </Toolbar>
          <AccountSwitcher accountId={accountId as string} collapsed={collapsed} />
          <List
            sx={{
              '& .MuiListItemIcon-root': {
                color: 'primaryColors.white',
              },
            }}
          >
            <InternalLinkListItemComponent
              list={{
                icon: <AppsIcon />,
                text: 'Projects',
                link: `/overview/account/${accountId as string}`,
              }}
              collapsed={collapsed}
            />
            <InternalLinkListItemComponent
              list={{
                icon: <ApiIcon />,
                text: 'API Tokens',
                link: '/configure/user/tokens',
              }}
              collapsed={collapsed}
            />
            <InternalLinkListItemComponent
              list={{
                icon: <SettingsIcon />,
                text: 'My Account',
                link: '/configure/user',
              }}
              collapsed={collapsed}
            />
            {(shouldShowSupportLinkFreeUsers ||
              (!projectsLoading && isPayingAccount) ||
              account?.type === 'enterprise') && (
              <InternalLinkListItemComponent
                list={{
                  icon: <ContactSupportIcon />,
                  text: 'Support',
                  link: '/support',
                }}
                collapsed={collapsed}
              />
            )}
            <ExternalLinkListItemComponent
              list={{
                icon: <MonitorHeart />,
                text: 'Status',
                link: 'https://status.section.io/',
                openInNewTab: true,
              }}
              collapsed={collapsed}
            />
            <ExternalLinkListItemComponent
              list={{
                icon: <ArticleIcon />,
                text: 'Documentation',
                link: 'https://www.section.io/docs/',
                openInNewTab: true,
              }}
              collapsed={collapsed}
            />
            {shouldShowDiscordLink && (
              <ExternalLinkListItemComponent
                list={{
                  icon: <Image src={DiscordIcon} alt="Discord" title="Discord" width={24} height={24} />,
                  text: 'Discord',
                  link: 'https://discord.gg/section',
                  openInNewTab: true,
                }}
                collapsed={collapsed}
              />
            )}
            <ExternalLinkListItemComponent
              list={{
                icon: <LogoutIcon />,
                text: 'Log out',
                openInNewTab: false,
                action: () => {
                  logout({ returnTo: typeof window !== 'undefined' ? window.location.origin : '' })
                },
              }}
              collapsed={collapsed}
            />
          </List>
          <Box sx={{ display: 'flex', marginTop: 'auto', padding: '16px 32px', width: '100%' }}>
            {shouldShowCollapsibleSideMenu && !collapsed && (
              <ArrowBack
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  setCollapsedSideMenu(true)
                  setDrawerWidth(collapsedMenuWidth)
                }}
              />
            )}
            {shouldShowCollapsibleSideMenu && collapsed && (
              <ArrowForward
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  setCollapsedSideMenu(false)
                  setDrawerWidth(sideMenuWidth)
                }}
              />
            )}
          </Box>
        </Drawer>
        <Box component="main" sx={{ flexGrow: 1, bgcolor: 'background.default', p: 0 }}>
          {shouldShowNotificationsCenter && <NotificationCenter />}
          {children}
        </Box>
      </Box>
    </>
  )
}

// The purpose of this function is to allow us to return a complex nested tree of layouts
// https://adamwathan.me/2019/10/17/persistent-layout-patterns-in-nextjs/
export const getLayout: GetAppLayout = (page, hideNav = false) => <AppLayout hideNav={hideNav}>{page}</AppLayout>

export default AppLayout
