import { object, string } from 'yup'

export const createOrganizationSchema = object({
  name: string()
    .required()
    .matches(
      /^[A-Za-z0-9-. ]{1,253}$/,
      'Organization name can only contain letters, numbers, spaces, periods, and dashes.'
    ),
})
