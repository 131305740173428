import { createContext } from 'react'

type SelectedProjectContent = {
  selectedProjectEnvironment: string
  setSelectedProjectEnvironment: (c: string) => void
}

export const SelectedProjectEnvironmentContext = createContext<SelectedProjectContent>({
  selectedProjectEnvironment: '',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setSelectedProjectEnvironment: () => {},
})
