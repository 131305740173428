import React, { createContext } from 'react'

export type BannerNotificationLink = {
  href: string
  name: string | React.ReactElement
}

type _BannerNotification = {
  id: number
  icon?: React.ReactElement
  links?: BannerNotificationLink[]
  title?: string
  message: string
  variant: 'error' | 'success'
}

export type BannerNotification = Omit<_BannerNotification, 'id'>

type BannerAction =
  | { type: 'ADD'; notification: BannerNotification }
  | { type: 'REMOVE'; id: number }
  | { type: 'REMOVE_ALL' }

export const initialState: _BannerNotification[] = []

export const BannerContext = createContext(initialState)
// passes dummy function for typing
export const BannerDispatchContext = createContext<React.Dispatch<BannerAction>>(() => initialState)

export const ADD = 'ADD'
export const REMOVE = 'REMOVE'
export const REMOVE_ALL = 'REMOVE_ALL'
export const bannerReducer = (state: typeof initialState, action: BannerAction) => {
  switch (action.type) {
    case ADD:
      return [
        ...state,
        {
          id: +new Date(),
          ...action.notification,
        },
      ]
    case REMOVE:
      return state.filter((n) => n.id !== action.id)
    default:
      return state
  }
}
