import React, { useState } from 'react'

import { useRouter } from 'next/router'

import { useAuth0 } from '@auth0/auth0-react'
import { ArrowForward, MarkEmailReadOutlined, Replay } from '@mui/icons-material'
import { Box, Button, Container, Typography } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import Modal from '@mui/material/Modal'

import { consoleFetcher } from 'common/fetchers/graphql'
import { RESEND_VERIFICATION_EMAIL } from 'modules/user/queries'

export default function VerificationErrorOverlay({ errMessage }: { errMessage: string }) {
  const { user, getAccessTokenSilently, logout } = useAuth0()
  const [showProgress, setShowProgress] = useState(false)
  const [emailSent, setEmailSent] = useState(false)
  const [error, setError] = useState('')
  const router = useRouter()
  const auth0Id = errMessage.substring(errMessage.indexOf('auth0UserId:') + 'auth0UserId:'.length)

  const handleResendClick = async () => {
    setShowProgress(true)
    try {
      const response = await consoleFetcher(
        RESEND_VERIFICATION_EMAIL,

        {
          userID: user ? user.sub : auth0Id,
        },
        {
          tokenFn: getAccessTokenSilently,
        }
      )
      if (response?.resendVerificationEmail) {
        setShowProgress(false)
        setEmailSent(true)
      }
    } catch {
      setShowProgress(false)
      setError('Error sending verification email. Please try again or reach out to support.')
    }
  }

  return (
    <>
      <Modal BackdropProps={{ open: false }} open={true}>
        <>
          <Container maxWidth="xl">
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                height: '100vh',
                width: '100%',
              }}
            >
              <Box sx={{ textAlign: 'center' }}>
                <h1 style={{ lineHeight: 1.5 + 'em' }}>Please verify your email before proceeding further</h1>
              </Box>
              <Box sx={{ height: '100px', textAlign: 'center' }}>
                <Button
                  onClick={handleResendClick}
                  size="large"
                  color="secondary"
                  variant="contained"
                  sx={{ mr: '6px', textTransform: 'none', marginLeft: 'auto', minWidth: '160px' }}
                  endIcon={
                    showProgress ? (
                      <CircularProgress size={18} sx={{ color: 'white' }} />
                    ) : emailSent ? (
                      <MarkEmailReadOutlined />
                    ) : (
                      <Replay />
                    )
                  }
                >
                  {emailSent ? 'Verification Email Sent' : 'Re-send Verification Email'}
                </Button>
                {error && <Typography sx={{ color: 'red', mt: '10px' }}>{error}</Typography>}
              </Box>
              <Box sx={{ height: '50px', mt: '100px' }}>
                <Button
                  onClick={() => {
                    router.reload()
                  }}
                  color="primary"
                  variant="contained"
                  size="medium"
                  sx={{ mr: '6px', textTransform: 'none', marginLeft: 'auto', minWidth: '160px' }}
                  endIcon={<ArrowForward />}
                >
                  Email Verified
                </Button>
              </Box>
              <div>
                <a href="https://support.section.io/hc/en-us" target="_blank" rel="noreferrer">
                  <h5>Please contact support for more assistance.</h5>
                </a>
              </div>

              <Button
                color="primary"
                onClick={() => {
                  logout({
                    returnTo: typeof window !== 'undefined' ? window.location.origin : '',
                  })
                }}
              >
                Log Out
              </Button>
            </Box>
          </Container>
        </>
      </Modal>
    </>
  )
}
