import { useContext, useMemo } from 'react'

import { ADD, BannerContext, BannerDispatchContext } from './contexts/BannerContext'
import type { BannerNotification } from './contexts/BannerContext'

export default function useBannerNotification() {
  const state = useContext(BannerContext)
  const dispatch = useContext(BannerDispatchContext)
  const push = useMemo(
    () => (notification: BannerNotification) => {
      dispatch({ type: ADD, notification })
    },
    [dispatch]
  )

  return {
    notifications: state,
    push,
  }
}
