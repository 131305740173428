import React from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import { Box, Button, Container } from '@mui/material'
import Modal from '@mui/material/Modal'

export default function AuthErrorOverlay({ messageTitle }: { messageTitle: string }) {
  const { error, logout } = useAuth0()

  return (
    <>
      <Modal BackdropProps={{ open: false }} open={true}>
        <>
          <Container maxWidth="xl">
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                height: '100vh',
                width: '100%',
              }}
            >
              <Box sx={{ textAlign: 'center' }}>
                <h1 style={{ lineHeight: 1.5 + 'em' }}>{messageTitle}</h1>
                {error && <code>Error: {error?.message}</code>}
              </Box>
              <div>
                <a href="https://support.section.io/hc/en-us" target="_blank" rel="noreferrer">
                  <h5>Please contact support for more assistance.</h5>
                </a>
              </div>
              <Button
                color="primary"
                onClick={() => {
                  logout({
                    returnTo: typeof window !== 'undefined' ? window.location.origin : '',
                  })
                }}
              >
                Log Out
              </Button>
            </Box>
          </Container>
        </>
      </Modal>
    </>
  )
}
