import React, { useState } from 'react'

import { SelectedProjectEnvironmentContext } from '../contexts/SelectedProjectEnvironmentContext'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const SelectedProjectEnvironmentProvider = ({ children }) => {
  const [selectedProjectEnvironment, setSelectedProjectEnvironment] = useState('')
  return (
    <SelectedProjectEnvironmentContext.Provider
      value={{
        selectedProjectEnvironment,
        setSelectedProjectEnvironment,
      }}
    >
      {children}
    </SelectedProjectEnvironmentContext.Provider>
  )
}
export default SelectedProjectEnvironmentProvider
