declare module '@mui/material/styles' {
  // https://mui.com/material-ui/customization/palette/#adding-new-colors
  interface Palette extends CustomThemeColors {
    box: Palette['primary']
    inputBorder: Palette['primary']
    inputBox: Palette['primary']
  }

  // https://mui.com/material-ui/customization/palette/#adding-new-colors
  interface PaletteOptions extends CustomThemeColors {
    box: PaletteOptions['primary']
    inputBorder: PaletteOptions['primary']
    inputBox: PaletteOptions['primary']
  }
}

interface PrimaryColors {
  neonPrimary: string
  regGreen: string
  lightGreen: string
  offBlack: string
  offWhite: string
  textColor: string
  textHighlighted: string
  modalWhite: string
  white: string
  legacyGreen: string
  modalBlack: string
  loudGrey: string
  darkFormGrey: string
  actionGrey: string
  actionGreyHover: string
  linkBlue: string
  linkBlueHover: string
  onDarkActionGrey: string
  onDarkLabelsGrey: string
  inactive: string
  labelsGrey: string
  lowLevelBlue: string
  textFieldTan: string
  alertRed: string
  blue: string
}

interface TrafficColors {
  alertRed: string
  healthyBlue: string
  warningOrange: string
}

interface SectionColors {
  sectionDarkGreen: string
  sectionVeryLightGreen: string
  sectionRegGreen: string
  offBlack: string
  offWhite: string
}

interface ActivityColors {
  onLightInteractiveGreen: string
  selectedNeonGreen: string
  interactiveGreen: string
  hoverLinkBlue: string
}

const primaryColors: PrimaryColors = {
  neonPrimary: '#31EA91',
  regGreen: '#0075c9', // Lumen Blue
  lightGreen: '#32b988',
  offBlack: '#141C2B',
  offWhite: '#eaeaea',
  textColor: '#969696',
  textHighlighted: '#faf9f7',
  modalWhite: '#F6F6F6',
  white: '#ffffff',
  legacyGreen: '#23423A',
  modalBlack: '#1f2635',
  loudGrey: '#727780',
  darkFormGrey: '#49505B',
  actionGrey: '#6c6c6b',
  actionGreyHover: '#404040',
  linkBlue: '#369FDA',
  linkBlueHover: '#1c6a96',
  onDarkActionGrey: '#E8E9E9',
  onDarkLabelsGrey: '#D8D8D8',
  inactive: '#727780',
  labelsGrey: '#404753',
  lowLevelBlue: '#38425E',
  textFieldTan: '#D6CC9A',
  alertRed: '#FF686D',
  blue: '#1D9AD1',
}

const trafficColors: TrafficColors = {
  alertRed: '#FF686D',
  healthyBlue: '#3BA0F1',
  warningOrange: '#DB9F3F',
}

const sectionColors: SectionColors = {
  sectionDarkGreen: '#038754',
  sectionVeryLightGreen: '#43D19A',
  sectionRegGreen: '#00A86B',
  offBlack: '#141C2B',
  offWhite: '#eaeaea',
}

const activityColors: ActivityColors = {
  onLightInteractiveGreen: '#038754',
  selectedNeonGreen: '#31EA91',
  interactiveGreen: '#34C37F',
  hoverLinkBlue: '#3BA0F1',
}

export interface CustomThemeColors {
  primaryColors: PrimaryColors
  trafficColors: TrafficColors
  sectionColors: SectionColors
  activityColors: ActivityColors
}

export const customThemeColors: CustomThemeColors = {
  primaryColors,
  trafficColors,
  sectionColors,
  activityColors,
}
